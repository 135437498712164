<template>
  <cs-page class="views-error-mobile">
    <div class="text-center position-absolute top-50 start-50 translate-middle">
      <cs-qrcode
        :code="code"
        :logoSrc="require('../../assets/logo.png')"
        :size="250"
      />
      <h5 style="font-size: 16px; margin-top: 20px; margin-bottom: 0px">
        请在手机端打开页面
      </h5>
      <span style="font-size: 14px; color: #8d8d8d"
        >手机扫一扫，进入该页面</span
      >
    </div>
  </cs-page>
</template>
<script>
export default {
  data() {
    return {
      code: "",
    };
  },
  created() {
    let { url } = this.$route.query;
    this.code = `${location.origin}/portal-wap/#${decodeURIComponent(url)}`;
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.views-error-mobile {
  background: white;
}
</style>